<template>
	<div>
		<div class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper">
				<table>
					<thead>
						<tr>
							<th v-for="(th, key) in sortedThead" :key="key" :width="th.width">
								<v-layout>
									<v-flex class="text-left">
										<template v-if="th.checkbox">
											<v-checkbox dense v-model="checkbox" hide-details class="m-0 p-0"></v-checkbox>
										</template>
										<template v-else>
											{{ th.name }}
										</template>
									</v-flex>
									<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
										<template v-if="th.sort == 'ASC'"
											><v-icon small link>mdi-sort-ascending</v-icon></template
										>
										<template v-if="th.sort == 'DESC'"
											><v-icon small link>mdi-sort-descending</v-icon></template
										>
									</v-flex>
								</v-layout>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(td, bkey) in tbody">
							<v-hover :key="`row-hover-${bkey}`" v-slot="{}">
								<tr :key="`row-${bkey}`" v-on:click="leadDetails(td.id)">
									<template v-for="(th, hkey) in sortedThead">
										<td :key="`first-${hkey}-${bkey}`" :width="th.width" :class="{ 'td-link': th.order > 1 }">
											<template v-if="th.checkbox">
												<v-checkbox dense :value="td.uuid" hide-details class="m-0 p-0" multiple></v-checkbox>
											</template>
											<template v-else-if="th.key == 'action'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															fab
															dark
															x-small
															color="green"
															v-bind="attrs"
															v-on="on"
															class="mr-3"
															v-on:click.stop.prevent="leadUpdate(td.id)"
														>
															<v-icon>mdi-pencil</v-icon>
														</v-btn>
													</template>
													<span>Edit {{ type }}</span>
												</v-tooltip>
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															fab
															dark
															x-small
															color="red"
															v-bind="attrs"
															v-on="on"
															v-on:click.stop.prevent="leadDelete(td)"
														>
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</template>
													<span>Delete {{ type }}</span>
												</v-tooltip>
											</template>
											<template v-else-if="th.key === 'image'">
												<ImageTemplate circle style="max-width: 50px" :src="td.image"></ImageTemplate>
											</template>
											<template v-else-if="th.key === 'barcode'">
												<Chip :text="td.barcode" color="cyan"></Chip>
											</template>
											<template v-else-if="th.key === 'status'">
												<Chip :text="td.status" :color="td.status_color"></Chip>
											</template>

											<template v-else-if="th.key === 'modified_at'">
												<TableDateTime
													v-if="td.modified_at"
													:human-format="td.modified_at"
													:system-format="td.updated_at"
												></TableDateTime>
												<span v-else>-</span>
											</template>
											<template v-else-if="th.key == 'progress'">
												<span v-if="td.progress">
													<span class="mr-2 fw-600" :class="`${td.status_color}--text`">{{ td.progress }}%</span>
													<v-progress-circular
														:rotate="360"
														:size="30"
														:width="4"
														:value="td.progress"
														:color="td.status_color"
													>
													</v-progress-circular>
												</span>
												<span v-else>-</span>
											</template>

											<template v-else>
												<ShowValue :object="td" :object-key="th.key" :label="th.name"></ShowValue>
											</template>
										</td>
									</template>
								</tr>
							</v-hover>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<DeleteTemplate
			type="Lead"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				getLeadLists();
			"
		></DeleteTemplate>
		<v-row class="listing-footer py-2">
			<v-col class="my-auto h6 text-capitalize"
				><b>Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</b>
			</v-col>
			<v-col class="my-auto">
				<v-pagination v-model="currentPage" :length="totalPages" v-on:input="updatePagination">
				</v-pagination
			></v-col>
		</v-row>
	</div>
</template>
<script>
import Chip from "@/view/components/Chip";
import TableDateTime from "@/view/components/TableDateTime";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";

import { GET, QUERY } from "@/core/services/store/request.module";

export default {
	name: "LeadsListing",
	components: {
		Chip,
		ShowValue,
		TableDateTime,
		DeleteTemplate,
	},
	props: {
		statusFilterData: {
			type: Array,
		},
	},
	watch: {
		statusFilterData: {
			handler(param) {
				this.tbody = param;
			},
		},
	},
	data() {
		return {
			delURL: null,
			delTEXT: null,
			confirm_dialog: false,
			type: null,
			checkbox: false,
			currentPage: 1,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			showingFrom: 0,
			showingTo: 0,
			tbody: [],
			sortedThead: [
				{
					id: 1,
					type: "lead",
					key: "uuid",
					name: "uuid",
					sort: null,
					fixed: true,
					sortable: false,
					checkbox: true,
					visible: true,
					width: 50,
					order: 0,
				},
				{
					id: 2,
					type: "lead",
					key: "action",
					name: "Action",
					sort: null,
					fixed: true,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 120,
					order: 1,
				},
				{
					id: 3,
					type: "lead",
					key: "barcode",
					name: "Lead #",
					sort: null,
					fixed: true,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 2,
				},
				{
					id: 4,
					type: "lead",
					key: "customer_name",
					name: "Customer",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 3,
				},
				{
					id: 5,
					type: "lead",
					key: "company_name",
					name: "Company",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 4,
				},
				{
					id: 6,
					type: "lead",
					key: "email",
					name: "Email",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 5,
				},
				{
					id: 7,
					type: "lead",
					key: "phone",
					name: "Phone",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 6,
				},
				{
					id: 8,
					type: "lead",
					key: "lead_date",
					name: "Lead date",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 7,
				},
				{
					id: 9,
					type: "lead",
					key: "position",
					name: "Position",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 10,
					type: "lead",
					key: "address_line_1",
					name: "Address",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 11,
					type: "lead",
					key: "postal_code",
					name: "Postal Code",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 12,
					type: "lead",
					key: "country",
					name: "Country",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 13,
					type: "lead",
					key: "website",
					name: "Website",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 14,
					type: "lead",
					key: "amount",
					name: "Amount",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					width: 200,
					order: 8,
				},
				{
					id: 15,
					type: "lead",
					key: "status",
					name: "Status",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: false,
					width: 200,
					order: 9,
				},
				{
					id: 16,
					type: "lead",
					key: "source",
					name: "Source",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: false,
					width: 200,
					order: 9,
				},
				{
					id: 17,
					type: "lead",
					key: "opportunity",
					name: "Opportunity",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: false,
					width: 200,
					order: 9,
				},
				{
					id: 18,
					type: "lead",
					key: "product_type",
					name: "Product Type",
					sort: "",
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: false,
					width: 200,
					order: 9,
				},
			],
		};
	},

	methods: {
		getLeadLists() {
			this.$store
				.dispatch(GET, { url: "leads" })
				.then((data) => {
					this.tbody = data.rows;
					this.totalPages = Math.ceil(data.totalRows / data.perPage);
					this.currentPage = data.currentPage;
					this.totalRows = data.totalRows;
					this.rowsOffset = data.offset;
					this.showingFrom = data.showingFrom;
					this.showingTo = data.showingTo;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		leadDetails(id) {
			this.$router.push({
				name: "leads-detail",
				params: { id },
			});
		},
		leadUpdate(id) {
			this.$router.push({
				name: "update-lead",
				params: { id },
			});
		},
		leadDelete(row) {
			this.delTEXT = row.barcode;
			this.delURL = `lead/${row.id}`;
			this.confirm_dialog = true;
		},
		updatePagination() {
			const current_page = this.currentPage;
			this.$store
				.dispatch(QUERY, { url: `leads`, data: { current_page: current_page } })
				.then((data) => {
					this.tbody = data.rows;
					this.showingFrom = data.showingFrom;
					this.showingTo = data.showingTo;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},

	mounted() {
		this.getLeadLists();
	},
};
</script>
